@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Proxima Nova', 'Curier new';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {

  /* Colors: */
  --main-color: #13C4A3;
  --main-light-color: #22d3b3;
  --main-dark-color: #12B093;
  --link-color: #39A0ED;
  --decline-color: #8011BC;
  --attention-color: #C40EC5;
  --background-color: #252435;
  --background-light-color: #33324A;
  --background-dark-color: #1E1D2B;
  --background-separators: #51505D;
  --tag-color: #3A3854;
  --title-text-color: #FFFFFF;
  --subtitle-text-color: #CBCBCB;
  --text-color: #BFBFC4;
  --text-dark-color: #94949D;
  --dark-overlay: rgba(0,0,0,0.5)
}

a{
  color: #39A0ED;
  color: var(--link-color)
}

body {
  background-color: #252435;
  background-color: var(--background-color);
  font-family: proxima-nova,sans-serif;
  scrollbar-base-color: transparent;
  scrollbar-color: transparent;
}

button {
  font-size: 18px;
  letter-spacing: 0.05em;
  font-family: 'Courier New', Courier, monospace;
  color: white;
  font-weight: bold;
  background-color: #13C4A3;
  background-color: var(--main-color);
  border-radius: 30px;
  border-style: unset;
  padding: 0.6em 2em;
  margin: 0.2em;
  min-width: 200px;
}

button:hover {
  background-color: #22d3b3;
  background-color: var(--main-light-color) 
}

button:focus { 
  outline: none; 
}

button:disabled{
  opacity: 0.20;
}

hr{
  border: 1px solid #51505D;
  border: 1px solid var(--background-separators);
  margin: 0px;
  margin-top: 0.3em;
  opacity: 0.40;
}
h1{
  font-size: 40px;
  font-family: 'Courier New', Courier, monospace;
  color: #13C4A3;
  color: var(--main-color)
}
h2{
  font-size: 32px;
  font-family: 'Courier New', Courier, monospace;
}
h4{
  font-size: 24px;
  margin: 0.7em 0em;
}

p{
  font-family: proxima-nova,sans-serif;
  font-size: 18px;
}
span{
  font-size: small;
  color: #51505D;
  color: var(--background-separators);
}
input, select, textarea {
  outline: 0;
  border-style: solid;
  border-width: 0 0 3px;
  border-color: #51505D;
  border-color: var(--background-separators);
  background-color: transparent;
  font-size: 16px;
  padding-bottom: 1em;
  color: #BFBFC4;
  color: var(--text-color);
  font-family: proxima-nova,sans-serif;
}
textarea{
  border-width: 3px;
}

[draggable=true] {
  cursor: move;
}
.decline-button{
  background-color: #8011BC;
  background-color: var(--decline-color);
}
.decline-button:hover{
  background-color: #C40EC5;
  background-color: var(--attention-color);
}
.secondary-button{
  background-color: transparent;
  padding: 0.6em 1em;
  min-width: auto;
  opacity: 0.80;
  border-radius: 0px;
}

.secondary-button:hover{
  background-color: transparent;
  opacity: 1.0;
}
.secondary-button:hover:disabled{
  opacity: 0.20;
}

.close-button{
  width: 60px;
  height: 60px;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACqADAAQAAAABAAAACgAAAAAYsJrPAAAAkUlEQVQYGW2QQQ5CIRBDWy6mNxFvoFu/S3H7vYHfm+jJxik6hBC6IEx56TSwlDUD3C3L6YiJ7rd1M+KdaNgTyKU8niMnCOThzwBhGLBFcngwe12u5+xhP8WDYJov86SARDRQQ8C695DGpGMmozfr1BJbmneq776671zBHlJxgeEFzDDGTiOc9JkzSGBNr1Xs8wXOV15Aakt4oQAAAABJRU5ErkJggg==);
  align-self: flex-end;
}
.loader{
  height: 5em;
  width: 5em;
}
.loader-container{
  margin: 2em;
  align-items: center;
  min-width: 10em;
  min-height: 10em;
  display: flex;
  justify-content: center;
}
.interests-nav{
  display: inline-flex;
  width: 100%;
  margin-top: 1em;
  justify-content: center;
}
.curation-score{
  color: #13C4A3;
  color: var(--main-color)
}
.curation-score img{
  width: 1em;
  height: 1em;
}
.tab-button{
  font-family: proxima-nova,sans-serif;
  margin: 0em 1em;
  min-width: 20%;
}
.tab-button.selected{
  opacity: 1.0;
  outline: 0;
  border-style: solid;
  border-width: 0 0 3px;
  border-color: #13C4A3;
  border-color: var(--main-color);
}
.content-container{padding: 1em;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.light-border{
 border-radius: 6px;
 border-width: 2px;
 border-color: #51505D;
 border-color: var(--background-separators);
 border-style: solid;
}
.profile-pic{
  border-radius: 50%;
  background-color: #CDC6E4;
}
.App-link {
  color: #61dafb;
}

.App-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.general-container{
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 12em auto;
  align-items: stretch;
}

.popup-container{
  position: fixed;
  width: 100%;
  height: 100%;
  display:flex;
  z-index: 1;
  background-color: rgba(0,0,0,0.5);
  background-color: var(--dark-overlay);
  justify-content: center;
  align-items: center;
}

.popup{
  background-color: #252435;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;

}


/* Landing: Register or login */

.App-logo {
  pointer-events: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}
#App-punch-line img{
  max-width: 13em;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.arrow-list > li {
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASCAYAAABit09LAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACqADAAQAAAABAAAAEgAAAABIIEaMAAAAhUlEQVQoFaWQiwnAMAhENXSAjtTR0s0yUjdotag1cAShByGJn+cldH/qtFBb5KYUC7Bb5JJ91zMzd92htMFdoILyaNRMTk62qExkhEykeCCqi5g2uI3yaFjoFEXLn76ChTE7HeIxQjkkritrCG7kgP5dGJ8SdimPbsj4L+Im3acRBiJ57AG+61khmCrhzwAAAABJRU5ErkJggg==); 
  font-family: 'Courier New', Courier, monospace;
  font-size: 22px;
  margin-bottom: 0.6em;
  /* TODO: adds space between the marker and the text*/
}

.landing-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 2em;
  -webkit-column-gap: 2em;
          column-gap: 2em;
  width: 55%;
}
.landing-container a{
  margin: 0px 0.5em;
}

.access-card{
  width: 100%; 
  border-radius: 15px;
  border-color: #22d3b3;
  border-color: var(--main-light-color);
  border-width: 3px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  max-width: 10em;
}
.access-card p{
  text-align:left;
  margin-bottom:2em;
  line-height: 1.5em;
}



/* Register */
.register-container{
  display: grid;
  width: 90%;
  height: 100vh;
  grid-template-columns: 50% 50%;
  grid-template-rows: 15% 70% 15%;
  grid-template-areas: 
    "header header"
    "form interest"
    "action action";
  align-items: center;
}

.register-container h2{
  font-size: 28px;
}

/* Register: From and interest synch */
.register-logo{
grid-area: header;
justify-self: center;
max-width: 8em;
}

.register-button{
grid-area: action;
justify-self: center;
align-self: center;
}

.register-info{
grid-area: form;
justify-self: center;
width: 80%;
}

.register-interest{
grid-area: interest;
justify-self: center;
width: 80%;
height: 80%;
}


.synchronize-interests{
border-style: solid;
border-color: #51505D;
border-color: var(--background-separators);
border-radius: 6px;
border-width: 3px;
min-height: 400px;
padding: 1em;
padding-top:0px;
height: 80%;
}

.dropping-area{
background-color: #1E1D2B;
background-color: var(--background-dark-color);
border-radius: 10px;
height: 2em;
display: flex;
align-items: center;
border-width: 1px;
border: dashed;
border-color: #51505D;
border-color: var(--background-separators);
margin: 0.7em 0em;
}

.dropping-area span{
font-size: 20px;
margin: 0.4em auto;
opacity: 0.60;
}

.tag{
display: inline-flex; 
align-items: center;
background-color:#3A3854;
background-color:var(--tag-color);
border-radius: 18px;
justify-content: space-evenly;
margin: 0.2em 0.2em;
padding: .0em .8em;
}
.tag a{
  color: #FFFFFF;
  color: var(--title-text-color);
  font-size: 20px;
margin: .6em .0em;
text-decoration: none;
pointer-events: painted;
}
.tag a:hover{
  text-decoration: underline;
}

.row{
display: inline-flex;
justify-content: space-between;
width: 100%;
}
.item{
width: 100%;
padding: 0px 1em;
}

.item textarea{
width: 100%;
height: 3em;
padding: 0.5em;
}
.item input, .item select{
width: 100%;
}

.item input:disabled, .item select:disabled{
  opacity: 0.20;
}

.form-error{
font-size: small;
color: #C40EC5;
margin-top: 4px;
}



/* MENU STYLES */

.menu-container{
  font-family: proxima-nova,sans-serif;
  height: 100%;
  background-color: #13C4A3;
  background-color: var(--main-color);
  /* min-width: 16em; */
}
.menu-container .App-logo{
  max-width: 75%;
  /* max-width: 8em; */
}

.menu-tabs{
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.menu-tab{
  padding-top: 1em;
  border-radius: 1px;
  margin: 0.3em 0px;
  text-align: left;
  padding-left: 3em;
  height: 3em;
  font-size: 18px;
  font-family: proxima-nova,sans-serif;
  text-transform: uppercase;
  display: inline-flex;
}

.menu-tab:hover{
  background-color: #22d3b3;
  background-color: var(--main-light-color); 
}
.menu-tab.active{
  background-color: #12B093;
  background-color: var(--main-dark-color);
}
.menu-tab.logout{
  position: absolute;
  left: 0px;
  bottom:0px;
  align-self: flex-end;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  opacity: 0.60;
}

.menu-tab.logout:hover{
  background-color: transparent;
  opacity: 1.0;

}

/* Profile browser */

.profile-container{
  text-align: center;
  width: 80%;
  margin: 20px;
  flex-grow: 1;
  display: grid;
  grid-template-rows: 45% 55%;
}
.profile-nav{
  align-self: center;
  margin-bottom: 1em;
}
.profile-header {
  background-color: #1E1D2B;
  background-color: var(--background-dark-color);
  display: inline-flex;
  border-radius: 6px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.profile-header .profile-pic{
  width: 2em;
  height: 2em;
  margin-right: 1em;
}
.profile-username{
  color: #13C4A3;
  color: var(--main-color);
}


.profile-info label{
  margin-right: 1em;
  font-size: 18px;
  font-weight: bold;
  color: #CBCBCB;
  color: var(--subtitle-text-color);
}

.get-info-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.profile-info p{
  font-size: 18px;
  color: #BFBFC4;
  color: var(--text-color);
  display: inline;
  line-height:1.5em;
}
.info{
  text-align: left;
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: self-start;
}
.info-column{
  display: inline-grid;
  margin: 0.5em 1.5em;
  max-width: 50%;
}
.info-item{
  display: inline;
  margin-bottom: 0.25em;
}
.interests-container{
  margin: 0em 2em;
  min-height: 7em;
  border-radius: 10px;
  padding: 0.5em;
  background-color: #1E1D2B;
  background-color: var(--background-dark-color);
}

/* Icebreaker styles */
.icebreaker{
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 1em;
  text-align: center;
}
.icebreaker select{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
}
.post-container{
 padding: 1em;
 /* margin: 1em; */
 align-content: center;
 text-align: left;
 min-width: 10em;
 /* width: 80%; */
}
.post-container a{
  text-decoration: none;
  font-weight:400;
  line-height: 1.5em;
}
.icebreaker .item{
  display: inline-flex;
  align-items: center;
}
.icebreaker h2{
  font-size: 28px;
  margin-top: 0px;
}

.icebreaker h4{
  font-size: 22px;
  color: #CBCBCB;
  color: var(--subtitle-text-color);
  margin-right: 1em;
}

.platform-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

/* For formatting the icebreakers. */

.subreddit-name {
  font-weight: bold;
  font-size: 24px;
  margin: 0em 0em;
  padding: 0em 0em;
}

.reason {
  color: #51505D;
  margin: 0.25em 0em 0.5em 0em;
  /* margin: 0em 0em; */
  padding: 0em 0em;
  font-size: 18px;
}

.post-title {
  color: white;
  margin: 0em 0em;
  padding: 0em 0em;
  font-size: 20px;
  font-weight: bold;
}

.post-text {
  font-size: 18px;
  margin: 0em 0em;
  padding: 0em 0em;
  overflow-wrap: break-word;
}

/* For the profile edition */
.edit-container{
  display: block;
  text-align: center;
  max-width: 650px;
  padding: 0em 1em 1em 1em;
}
.edit-info{
  text-align: left;
  margin: 1em;
}
.picture-selection{
  display: grid;
  height: 15em;
  justify-content: center;
}
.picture-selection input{
  border-style: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.picture{
  max-height: 100%;
  max-width: 100%;
}

.settings-container{
  padding: 2em;
  text-align: left;
}

.settings-container button{
  font-size: 16px;
}

/* Chats menu styles */

.chats-container{
  width: 100%;
  display: grid;
  grid-template-columns: 9em auto;
  align-items: stretch;
}

.chat-menu-container{
  font-family: proxima-nova,sans-serif;
  background-color: #33324A;
  background-color: var(--background-light-color);
  padding-top:2em; 
}

.chat-type-header{
  font-family: 'Courier New', Courier, monospace;
  color: #94949D;
  color: var(--text-dark-color);
  text-transform: uppercase;
  padding-left: 1em;
  font-weight: bold;
}

.chat-menu-tabs{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.chat-menu-tab{
  text-align: left;
  padding-left: 1em;
  border-radius: 1px;
  display: inline-flex;
  align-items: center;
  height: 3em;
  font-weight: 600;
  font-family: proxima-nova,sans-serif;
  font-size: 14px;
  background-color: #33324A;
  background-color: var(--background-light-color);
}
.chat-menu-tab img{
  height: 2em;
  width: 2em;
  margin-right: 1em;
}

.chat-menu-tab:hover{
  background-color: #252435;
  background-color: var(--background-color); 
}

.chat-menu-tab.active{
  display: inline-flex;
  align-items: center;
  background-color: #252435;
  background-color: var(--background-color); 
  color: #13C4A3; 
  color: var(--main-color)
}


/* Chat */

.chat-container{
  height: 100vh;
  flex-direction: column;
  display: flex;
  /* overflow-y: scroll; */
  justify-content: center;
  scrollbar-color: transparent;

}

.chat-container::-webkit-scrollbar-track {
  background-color: transparent;
  color: transparent
}

.chat-header {
  /* outline: 0;
  border-style: solid;
  border-width: 0 0 3px;
  border-color: var(--background-separators); */
  background-color: #1E1D2B;
  background-color: var(--background-dark-color);
  padding: 0px 1em;
  /* height: 2.5em; */
  display: inline-flex;
  align-items: center;
  margin: 0px;
}
.chat-header .profile-pic{
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
}
main{
  width: 90%;
  padding: 0px 5%;
  justify-self: center;
  overflow-y: scroll;
}

.chat{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3em auto 2em;
  align-items: stretch;
}
.message {
  display: flex;
  align-items: center;
}
.chat-input {
  display: inline-flex;
  align-items: center;
  padding: 0.5px 0.5em;
}
.chat-input input{
  border-width: 2px;
  border-style: solid;
  border-radius: 50px;
  padding: 0.2em 1em 0.5px 1em;
  border-color: #51505D;
  border-color: var(--background-separators);
  height: 2em;
  margin-right: 1em;
}
.send-chat-button{
  font-size: 14px;
  min-width: 6em;
  height: 3em;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #12B093;
  background: var(--main-dark-color);
  align-self: flex-end;
  padding: 0.5em;
  border-radius: 30px 1px 30px 30px;
  padding: 0.7em 1em;
}
.received p {
  background: #e5e5ea;
  color: black;
  padding: 0.5em;
  border-radius: 1px 30px 30px 30px;
  padding: 0.7em 1em;
}
.request-container {
  overflow-y: scroll;
  height: 100vh;
  padding: 2em;
}
.request-container .profile-container{
 width: auto;
 margin:0;
}
.request h2{
  font-size: 22px;
}
.request-answer{
  /* width: 100%; */
  display: flex;
  justify-content: center;
  padding: 1em;
}


input {
  width: 100%;
}

.no-results {
  color: #CBCBCB;
  color: var(--subtitle-text-color);
  font-family: proxima-nova,sans-serif;
  align-self: center;
}

#tooltip{
  position: relative;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
}

.tooltip-label{
  position: absolute;
  max-width: 250px;
  padding: 0.7em 1em;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.hide{ 
  display: none;
}

.show{
  display: block;
}

::-webkit-inner-spin-button {
  display: none;
}
::-webkit-scrollbar-track {
  background: transparent !important;
  color: transparent !important;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent !important;
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent  !important;
}


/* For animation references */

@media (prefers-reduced-motion: no-preference) {
  .loader {
    animation: loader-spin infinite 2s linear;
  }
} 

@keyframes loader-spin {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  0%   {transform: rotate(0deg)}
  12.5%  {transform: rotate(45deg)}
  25%  {transform: rotate(90deg)}
  37.5%  {transform: rotate(135deg)}
  50%  {transform: rotate(180deg)}
  62.5%  {transform: rotate(225deg)}
  75%  {transform: rotate(270deg)}
  87.5%  {transform: rotate(315deg)}
  100% {transform: rotate(360deg)}
}

.file-display-container {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

.file-status-bar{
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  position: absolute;
  font-size: 5px;
  font-weight: 700;
  padding: 1px;
  border-radius: 1px;
  background: #39A0ED;
  background: var(--link-color);
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align:top;
  margin-left: 30px;
  color: #39A0ED;
  color: var(--link-color);
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
  color: #BFBFC4;
  color: var(--text-color);
}

.file-error-message {
  color:#C40EC5;
  color:var(--attention-color);
}

.file-type-logo {
  width: 20px;
  height: 24px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAlCAYAAADMdYB5AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIaADAAQAAAABAAAAJQAAAADACAT6AAABhElEQVRYCe1YsU7DMBC9MwWRBVJghpmJCIbCgPor6Q5S/oB+BCNSyicwsrGBWMiMhFQx0wYmutTXc9KTorQKAZK2gy25Z/uu994921Fa9MLYdVBfAYDP3eX+70bTDMh2DHT97O9dFiVVDuguBwTcKyEgYIaAaWuAF63e4CWdzf/Es94gZlelBIwSQkJgee19ROoo6jQ/ZU2s4kGlBCRx3jKp/U3Ub2b7Z3yshGxh4nv0d/NF5L/z4/w0/HhAxPa8QAYbAqmTp06zL36jxEIbV7gDqF+Pw+G5AC+chAFmIusbqO8Pb+IDM18KiZQIOluNcbRUEik4uq0wDmpRAgGTCg1QUTM3grcmahQF/dX3DarrkAYC8vI5GNjjm7OdXa+FxPSBFGSBZJxcX4C2zI0atWyHAJS1loQoZZWwSogCYu2ZsEqIAmLtmbBKiAJi7ZmwSogCYlfiTMy86OZ/mwrbOu1KKKGI4LbOKkvk7qsRqACI7koEVxrCxX8RUSf7F0GlAL9NNgF/c1ahUJfavAAAAABJRU5ErkJggg==) no-repeat center center; 
  background-size: 100%;
  position: absolute;
}

.file-size {
  display:inline-block;
  vertical-align:top;
  margin-right:5px;
  margin-left: 10px;
  color: #CBCBCB;
  color: var(--subtitle-text-color);
  font-weight: 700;
  font-size: 14px;
}

.file-remove  {
  cursor: pointer;
  width: 10px;
  height: 10px;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACqADAAQAAAABAAAACgAAAAAYsJrPAAAAkUlEQVQYGW2QQQ5CIRBDWy6mNxFvoFu/S3H7vYHfm+jJxik6hBC6IEx56TSwlDUD3C3L6YiJ7rd1M+KdaNgTyKU8niMnCOThzwBhGLBFcngwe12u5+xhP8WDYJov86SARDRQQ8C695DGpGMmozfr1BJbmneq776671zBHlJxgeEFzDDGTiOc9JkzSGBNr1Xs8wXOV15Aakt4oQAAAABJRU5ErkJggg==);
}

